/*
    Wordpress custom classes
*/

const BORDER_COLORS = ['primary', 'secondary', 'dark', 'darkest']
const TACTICS_SPACING = '.5rem'
const TACTICS_DESKTOP_SPACING = '1rem'
const TACTICS_ICON_WIDTH = 100
const TACTICS_MOBILE_PADDING = '2em'

const getBorderClasses = (palette) => BORDER_COLORS.reduce((styles, border_color) => {
    return {
        ...styles,
        [`.border-${border_color}`]: {
            border: `1px solid ${palette.border[border_color]}`
        }
    }
})

export default ({ breakpoints, palette }) => ({
    // Borders
    ...getBorderClasses(palette),

    // Border class for WP content
    '.border-bottom': {
        borderBottom: `1px solid #9e9e9e`
    },

    // Colors
    '.has-gears-light-blue-color': { color: palette.primary.light },
    '.has-gears-blue-color': { color: palette.primary.main },
    '.has-gears-light-grey-color': { color: palette.text.secondary },
    '.has-gears-medium-grey-color': { color: palette.secondary.lightest },
    '.has-gears-dark-grey-color': { color: palette.secondary.light },
    '.has-gears-web-red-color': { color: palette.error.main },
    '.has-gears-red-color': { color: palette.error.main },
    '.has-gears-black-color': { color: palette.common.black },
    '.has-gears-white-color': { color: palette.common.white },
    '.has-gears-light-blue-background-color': { backgroundColor: palette.primary.light },
    '.has-gears-blue-background-color': { backgroundColor: palette.primary.main },
    '.has-gears-light-grey-background-color': { backgroundColor: palette.text.secondary },
    '.has-gears-medium-grey-background-color': { backgroundColor: palette.secondary.lightest },
    '.has-gears-dark-grey-background-color': { backgroundColor: palette.secondary.light },
    '.has-gears-web-red-background-color': { backgroundColor: palette.error.main },
    '.has-gears-red-background-color': { backgroundColor: palette.error.main },
    '.has-gears-white-background-color': { backgroundColor: palette.common.white },
    '.has-gears-black-background-color': { backgroundColor: palette.common.black },

    //Gradients
    '.has-gears-red-to-transparent-linear-gradient-background' : { background: `linear-gradient(${palette.primary.main} 0%,rgba(0,0,0,0) 100%)`},
    '.has-gears-medium-grey-to-transparent-linear-gradient-background' : { background: `linear-gradient(${palette.secondary.light} 0%,rgba(0,0,0,0) 100%)` },
    '.has-gears-black-to-transparent-linear-gradient-background' : { background: `linear-gradient(${palette.secondary.main} 0%,rgba(0,0,0,0) 100%)`},
    '.has-gears-white-to-transparent-linear-gradient-background' : { background: `linear-gradient(${palette.primary.contrastText} 0%,rgba(0,0,0,0) 100%)`},
    '.has-gears-red-to-transparent-radial-gradient-background' : { background: `linear-gradient(${palette.primary.main} 0%,rgba(0,0,0,0) 100%)`},
    '.has-gears-medium-grey-to-transparent-radial-gradient-background' : { background: `radial-gradient(${palette.secondary.light} 0%,rgba(0,0,0,0) 100%)` },
    '.has-gears-black-to-transparent-radial-gradient-background' : { background: `radial-gradient(${palette.secondary.main} 0%,rgba(0,0,0,0) 100%)` },
    '.has-gears-white-to-transparent-radial-gradient-background' : { background: `radial-gradient(${palette.primary.contrastText} 0%,rgba(0,0,0,0) 100%)` },
    '.has-gears-medium-grey-to-black-radial-gradient-background' : { background: `radial-gradient(${palette.secondary.light} 0%,#000 100%)` },
    '.has-gears-red-to-black-radial-gradient-background' : {background: `radial-gradient(${palette.primary.main} 0%,#000 100%)` },


    // Scrolling
    '.smooth-scroll': { scrollBehavior: 'smooth' },
    '.horizontal-scroll-mobile': {
        [breakpoints.down('sm')]: {
            paddingLeft: '1em',
            display: 'flex',
            flexDirection: 'row',
            overflowX: 'scroll',
            position: 'relative',
            flexWrap: 'nowrap',
            width: '100vw',
            marginLeft: 'calc(50% - 50vw)',
            WebkitOverflowScrolling: 'touch',
            msOverflowStyle: '-ms-autohiding-scrollbar',
            scrollSnapType: 'x mandatory',

            '> *': {
                display: 'flex',
                flexDirection: 'column',
                minWidth: '80%',
                paddingRight: '1em'
            },
            '.wp-block-column': { scrollSnapAlign: 'center' },
            // '.wp-block-column:not(:first-of-type)': { marginLeft: 0 },
        },
        [breakpoints.up('md')]: {
            overflowX: 'auto',
            '> *': { minWidth: 'auto' }
        }
    },

    '.wp-block-embed-youtube.wp-has-aspect-ratio.wp-embed-aspect-16-9': {
        '.wp-block-embed__wrapper': {
            position: 'relative',
            width: '100%',
            padding: '56.2431% 0 0 0',
            'iframe': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%'
            }
        }
    },

    // Tactics
    '[class^=hero-simplified] .tactics-overlay': {
        marginBottom: `calc(${TACTICS_SPACING} * 3)`,
        [breakpoints.up('md')]: {
            marginBottom: 0
        }
    },

    '.tactics-overlay': {
        display: 'block',
        border: `1px solid ${palette.border.primary}`,
        background: 'rgba(0, 0, 0, 0.8)',
        padding: TACTICS_MOBILE_PADDING,
        boxShadow: '0 0 50px black',
        [breakpoints.up('md')]: {
            padding: TACTICS_DESKTOP_SPACING
        },

        '& > *': {
            marginBottom: 0,
            paddingBottom: TACTICS_SPACING,
            [breakpoints.up('md')]: {
                paddingBottom: TACTICS_DESKTOP_SPACING
            },
        },
        '& > *:last-child': {
            paddingBottom: 0
        },
        '& hr': {
            borderBottom: `1px solid ${palette.border.primary}`,
            width: `calc(100% + (2 * ${TACTICS_SPACING}))`,
            marginLeft: `-${TACTICS_SPACING}`,
            paddingBottom: 0,
            marginBottom: 0,
            [breakpoints.up('md')]: {
                width: `calc(100% + (2 * ${TACTICS_DESKTOP_SPACING}))`,
                marginLeft: `-${TACTICS_DESKTOP_SPACING}`,
                marginBottom: TACTICS_DESKTOP_SPACING
            },
        },
        '& .wp-block-media-text': {
            flexDirection: 'row',
            [breakpoints.up('md')]: {
                gridTemplateColumns: `${TACTICS_ICON_WIDTH}px 1fr`
            },
            '& .wp-block-media-text__media': { maxWidth: `${TACTICS_ICON_WIDTH}px` },
            '& > [class^=MuiBox-root]':{
                padding: `0 ${TACTICS_SPACING} !important`, // because mediaTextContent has !important
                [breakpoints.up('md')]: {
                    padding: `0 ${TACTICS_DESKTOP_SPACING} !important`, // because mediaTextContent has !important
                },
                '& > *': { marginBottom: 0 }
            }
        }
    }
});
